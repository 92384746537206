<template>
  <div>
    <TableComponent :fields="fields" :items="streams" :busy="fetching">
      <template #cell(status)="slotProps">
        <BadgeComponent :variant="getBadgeVariant(slotProps.data)">
          {{ $tc(`app.${slotProps.data.toLowerCase()}`) }}
        </BadgeComponent>
      </template>
      <template #cell(actions)="slotProps">
        <router-link
          aria-haspopup="true"
          aria-expanded="false"
          v-tooltip.top="'Verificar'"
          :to="`history/${slotProps.item.id}`"
        >
          <span class="material-symbols-outlined title"> search </span>
        </router-link>
        <a
          v-if="slotProps.item.status == 'WAITING'"
          role="button"
          aria-haspopup="true"
          aria-expanded="false"
          v-tooltip.top="'Cancelar'"
          @click="cancel(slotProps.item.id)"
        >
          <span class="material-symbols-outlined title">
            cancel
          </span>
        </a>
      </template>
    </TableComponent>
    <pagination :totalPages="pages" v-model="form.page" @change="fetch"/>
  </div>
</template>

<script>
import moment from 'moment/moment';
import Pagination from '@/components/PaginationComponent.vue';
import TableComponent from '@/components/TableComponent.vue';
import BadgeComponent from '@/components/ui/BadgeComponent.vue';

export default {
  name: 'FlowHistoryTab',
  components: {
    BadgeComponent,
    TableComponent,
    Pagination,
  },
  props: {
    filters: {
      type: Object,
    },
    startDate: {
      type: String,
    },
    endDate: {
      type: String,
    },
  },
  data() {
    return {
      fetching: false,
      fields: [
        { key: 'creation_date', formatter: (value) => moment(value).format('DD/MM/YYYY HH:mm:ss') },
        {
          label: this.$tc('app.name', 1),
          key: 'client.name',
        },
        {
          label: this.$tc('app.sender', 1),
          key: 'client.phone',
        },
        'status',
        {
          label: this.$tc('app.flow', 1),
          key: 'flow.name',
        },
        {
          label: this.$tc('app.channel', 1),
          key: 'channel.name',
        },
        'actions',
      ],
      options: {
        timePicker: false,
        autoApply: true,
        className: 'form-control',
        maxSpan: {
          days: 31,
        },
        locale: {
          format: 'DD/MM/YYYY',
          daysOfWeek: ['Do', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sa'],
          monthNames: [
            'Jan',
            'Fev',
            'Mar',
            'Abr',
            'Mai',
            'Jun',
            'Jul',
            'Ago',
            'Set',
            'Otu',
            'Nov',
            'Dez',
          ],
        },
      },
      streams: [],
      form: {
        id: '',
        status: '',
        from: '',
        to: '',
        scheduled_at: {
          gte: '',
          lte: '',
        },
        order_by: ['scheduled_at', 'desc'],
        page: 1,
      },
      pages: 1,
      exporting: false,
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    resetPagination() {
      this.pages = 1;
      this.form.page = 1;
    },
    fetch(page = 1) {
      this.fetching = true;
      this.resetPagination();
      if (this.$route.params.campaignId) {
        this.filters.campaign_id = this.$route.params.campaignId;
      }
      this.$store
        .dispatch('fetchFlowStreams', {
          filter: {
            ...this.filters,
            createdAt: {
              gte: this.startDate,
              lt: this.endDate,
            },
          },
          page,
        })
        .then((response) => {
          this.streams = response.data;
          this.pages = response.last_page;
        })
        .finally(() => {
          this.fetching = false;
        });
    },
    cancel(streamId) {
      this.$store.dispatch('cancelFlowStream', streamId).finally(() => {
        this.fetch();
      });
    },
    exportData(format) {
      this.fetching = true;

      this.$store
        .dispatch('exportFlowstreams', {
          filter: {
            ...this.filters,
            scheduled_at: {
              gte: this.startDate,
              lt: this.endDate,
            },
          },
          format,
        })
        .then(() => {
          this.$toast.show({
            title: this.$t('generic-str.success'),
            content: this.$t('warnings.toast.report'),
            type: 'success',
          });
          this.$router.push({ name: 'FlowReportExports' });
        })
        .finally(() => {
          this.fetching = false;
        });
    },
    getBadgeVariant(status) {
      let variant = 'success';
      if (['AGENT_TOOK', 'TRANSFERRED', 'WAITING', 'EXECUTING'].includes(status)) {
        variant = 'light';
      } else if (status === 'CANCELED') {
        variant = 'secondary';
      } else if (['TIME_LIMIT_EXCEEDED', 'FAILED'].includes(status)) {
        variant = 'danger';
      } else {
        variant = 'success';
      }
      return variant;
    },
  },
};
</script>

<style scoped lang="scss">
.btn-search-id {
  height: 35px;
}

.btn-export {
  margin-left: 1px;
  padding: 8px 20px !important;
}

.m-top-10 {
  margin-top: 10px;
}

/* Rebranding */
.rebranding .card.borderless {
  box-shadow: unset !important;
}

.form-group {
  .icon_btn {
    display: flex;
    align-items: center;
    background-color: #fff;
    border: 1px solid var(--form-border);
    border-left-color: transparent;
    border-radius: 0.25rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    span {
      color: var(--gray-font-color);
    }
  }
}

.darkmode .form-group .icon_btn {
  background-color: var(--form-input);
  border-color: var(--form-border);
  border-left-color: transparent;
}

.icon-success {
  color: #6eda2c;
}

.icon-failed {
  color: #ff0000;
}
</style>
